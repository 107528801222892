
import { defineComponent } from 'vue';

export default defineComponent({
	setup() {},
	data() {
		return { showDropDown: false };
	},
	methods: {
		hide() {
			this.showDropDown = false;
		},
		show() {
			this.showDropDown = true;
		},
	},
});
