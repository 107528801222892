
import { defineComponent } from 'vue';
import LayoutDefault from '@/layouts/LayoutDefault.vue';

import openIssues from '@/components/dashboard/openIssues.vue';
import tabs from '@/components/base/tabs.vue';

export default defineComponent({
	name: 'Home',
	components: { LayoutDefault, openIssues, tabs },
	filters: {
		currency: function(value: number) {
			var formatter = new Intl.NumberFormat('da-DK', {
				style: 'currency',
				currency: 'DKK',

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});

			return formatter.format(value);
		},
	},
	data() {
		return {
			series: [
				{
					type: 'line',
					name: 'Revenue',
					data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
				},
				{
					type: 'bar',
					name: 'Revenue',
					data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
				},
			],

			options: {
				chart: {
					zoom: {
						enabled: false,
					},
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				tooltip: {
					y: {
						formatter: function(val: number) {
							return '$ ' + val + ' thousands';
						},
					},
				},
				plotOptions: {
					line: {
						horizontal: false,
						columnWidth: '25px',
						startingShape: 'rounded',
						endingShape: 'rounded',
					},
				},
				dataLabels: { enabled: false },
				stroke: {
					show: true,
					width: 2,
					curve: 'smooth',
				},
				xaxis: {
					categories: [
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
					],
				},
				yaxis: {
					title: {
						text: '$ (thousands)',
					},
				},
				fill: {
					opacity: 1,
				},
			},
		};
	},
});
