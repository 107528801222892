
import { defineComponent } from 'vue';
import baseLayout from '@/layouts/baseLayout.vue';

import headerBar from '@/components/header.vue';
import sideNavigation from '@/components/sideNavigation.vue';
import footerLine from '@/components/footer.vue';

export default defineComponent({
	name: 'LayoutDefault',
	components: { baseLayout, footerLine, headerBar, sideNavigation },
});
