import { createApp, Directive } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueApexCharts from 'vue3-apexcharts';

require('@/assets/scss/app.scss');

const clickOutside: Directive = {
	beforeMount: function(el: any, binding: any) {
		el.clickOutsideEvent = function(event: any) {
			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				binding.value();
			}
		};
		document.addEventListener('click', el.clickOutsideEvent);
	},
	unmounted: function(el: any) {
		document.removeEventListener('click', el.clickOutsideEvent);
	},
};

var vue = createApp(App)
	.directive('click-outside', clickOutside)
	.use(store)
	.use(router)
	.use(VueApexCharts)
	.mount('#app');
