
import { defineComponent } from 'vue';
import avaliableLanguages from '@/data/languages.json';

export default defineComponent({
	setup() {},
	data() {
		return {
			showDropDown: false,
			languages: [{}],
		};
	},
	methods: {
		hide() {
			this.showDropDown = false;
		},
		show() {
			this.showDropDown = true;
		},
	},
	async mounted() {
		this.languages = avaliableLanguages
			.filter((lang) => lang.active)
			.map((item) => ({
				...item,
				src: require(`@/assets/images/flags/${item.flag}`),
			}));

		console.log(this.languages);
	},
});
