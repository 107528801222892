<template>
	<div class="card">
		<div class="card-body">
			<div class="d-flex align-items-center justify-content-between mb-4">
				<h4 class="mb-0">Latest issues</h4>
				<button class="btn btn-outline-secondary btn-sm">
					+ Add new
				</button>
			</div>
			<table class="table table-hover mt-2">
				<thead>
					<tr>
						<th>Issue</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					<tr class="cursor-pointer">
						<td>
							<div class="d-flex align-items-center">
								<div class="ms-2">
									<div class="fw-bolder text-dark">
										#379 Crack in the windshiled
									</div>
									<div class="text-muted fw-semibold">
										Reported 2 months ago by Michael Brandt
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="badge-dot bg-success"></div>
							<span class="ms-2">Fixed</span>
						</td>
					</tr>
					<tr class="cursor-pointer">
						<td>
							<div class="d-flex align-items-center">
								<div class="ms-2">
									<div class="fw-bolder text-dark">
										#379 Crack in the windshiled
									</div>
									<div class="text-muted fw-semibold">
										Reported 2 months ago by Michael Brandt
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="badge-dot bg-info"></div>
							<span class="ms-2">Open</span>
						</td>
					</tr>
					<tr class="cursor-pointer">
						<td>
							<div class="d-flex align-items-center">
								<div class="ms-2">
									<div class="fw-bolder text-dark">
										#379 Crack in the windshiled
									</div>
									<div class="text-muted fw-semibold">
										Reported 2 months ago by Michael Brandt
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="badge-dot bg-warning"></div>
							<span class="ms-2">Overdue</span>
						</td>
					</tr>
					<tr class="cursor-pointer">
						<td>
							<div class="d-flex align-items-center">
								<div class="ms-2">
									<div class="fw-bolder text-dark">
										#379 Crack in the windshiled
									</div>
									<div class="text-muted fw-semibold">
										Reported 2 months ago by Michael Brandt
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="badge-dot bg-danger"></div>
							<span class="ms-2">Broken</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
