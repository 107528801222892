
import { defineComponent } from 'vue';

export default defineComponent({
	setup() {},
	data() {
		return {
			menuItems: [
				{
					name: 'Assets',
					icon: 'la-car la',
					route: '#',
				},
				{
					name: 'Workorders',
					icon: 'la-tools la',
					route: '#',
				},
				{
					name: 'Issues',
					icon: 'la-car-crash la',
					route: '#',
				},
				{
					name: 'Service',
					icon: 'la-oil-can la',
					route: '#',
				},
				{
					name: 'Reserve asset',
					icon: 'la-calendar la',
					route: '#',
				},
				{
					name: 'Inspections',
					icon: 'la-clipboard-check la',
					route: '#',
				},
				{
					name: 'Telemetry',
					icon: 'la-mixcloud la',
					route: '#',
				},
				{
					name: 'Fuel',
					icon: 'la-gas-pump la',
					route: '#',
				},

				{
					name: 'Analytics',
					icon: 'la-poll la',
					route: '#',
				},
				{
					name: 'Tachometer',
					icon: 'la-tachometer-alt la',
					route: '#',
				},
				{
					name: 'Economy',
					icon: 'la-money-bill la',
					route: '#',
				},

				{
					name: 'Live tracking',
					icon: 'la-satellite la',
					route: 'livetracking',
				},
				{
					name: 'Routes',
					icon: 'la-route la',
					route: '#',
				},
				{
					name: 'Places',
					icon: 'la-map-pin la',
					route: '#',
				},
				{
					name: 'Garages',
					icon: 'la-parking la',
					route: '#',
				},

				{
					name: 'Settings',
					icon: 'la-cog la',
					route: '#',
				},
				{
					name: 'Intergrations',
					icon: 'la-cubes la',
					route: '#',
				},
				{
					name: 'Intergrations',
					icon: 'la-link la',
					route: '#',
				},

				{
					name: 'Users',
					icon: 'la-user la',
					route: '#',
				},
			],
		};
	},
});
