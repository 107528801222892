
import { defineComponent } from 'vue';

import profileItem from '@/components/header/profile-item.vue';
import languageSelector from '@/components/header/language-selector.vue';
import settingsSelector from '@/components/header/setting-selector.vue';

export default defineComponent({
	name: 'Header',
	components: { profileItem, languageSelector, settingsSelector },
});
