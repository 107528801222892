<template>
	<ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
		<li class="nav-item" role="presentation">
			<button
				class="nav-link active"
				id="home-tab"
				data-bs-toggle="tab"
				data-bs-target="#home"
				type="button"
				role="tab"
				aria-controls="home"
				aria-selected="true"
			>
				Home
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button
				class="nav-link"
				id="profile-tab"
				data-bs-toggle="tab"
				data-bs-target="#profile"
				type="button"
				role="tab"
				aria-controls="profile"
				aria-selected="false"
			>
				Profile
			</button>
		</li>
		<li class="nav-item" role="presentation">
			<button
				class="nav-link"
				id="contact-tab"
				data-bs-toggle="tab"
				data-bs-target="#contact"
				type="button"
				role="tab"
				aria-controls="contact"
				aria-selected="false"
			>
				Contact
			</button>
		</li>
	</ul>
	<div class="tab-content" id="myTabContent">
		<div
			class="tab-pane fade show active"
			id="home"
			role="tabpanel"
			aria-labelledby="home-tab"
		>
			Placeholder content for the tab panel. This one relates to the home tab.
			Takes you miles high, so high, 'cause she’s got that one international
			smile. There's a stranger in my bed, there's a pounding in my head. Oh,
			no. In another life I would make you stay. ‘Cause I, I’m capable of
			anything. Suiting up for my crowning battle. Used to steal your parents'
			liquor and climb to the roof. Tone, tan fit and ready, turn it up cause
			its gettin' heavy. Her love is like a drug. I guess that I forgot I had a
			choice.
		</div>
		<div
			class="tab-pane fade"
			id="profile"
			role="tabpanel"
			aria-labelledby="profile-tab"
		>
			Placeholder content for the tab panel. This one relates to the profile
			tab. You got the finest architecture. Passport stamps, she's cosmopolitan.
			Fine, fresh, fierce, we got it on lock. Never planned that one day I'd be
			losing you. She eats your heart out. Your kiss is cosmic, every move is
			magic. I mean the ones, I mean like she's the one. Greetings loved ones
			let's take a journey. Just own the night like the 4th of July! But you'd
			rather get wasted.
		</div>
		<div
			class="tab-pane fade"
			id="contact"
			role="tabpanel"
			aria-labelledby="contact-tab"
		>
			Placeholder content for the tab panel. This one relates to the contact
			tab. Her love is like a drug. All my girls vintage Chanel baby. Got a
			motel and built a fort out of sheets. 'Cause she's the muse and the
			artist. (This is how we do) So you wanna play with magic. So just be sure
			before you give it all to me. I'm walking, I'm walking on air (tonight).
			Skip the talk, heard it all, time to walk the walk. Catch her if you can.
			Stinging like a bee I earned my stripes.
		</div>
	</div>
</template>
