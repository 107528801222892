import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "vertical-layout" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headerBar = _resolveComponent("headerBar")!
  const _component_sideNavigation = _resolveComponent("sideNavigation")!
  const _component_footerLine = _resolveComponent("footerLine")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_headerBar),
          _createVNode(_component_sideNavigation),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default"),
            _createVNode(_component_footerLine)
          ])
        ])
      ])
    ]),
    _: 3
  }))
}