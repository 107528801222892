<template>
	<div class="footer">
		<div class="footer-content">
			<p class="mb-0">
				Copyright © 2021 Fleet Improve. All rights reserved.
			</p>
			<span>
				<a href="" class="text-gray me-3">
					<i class="me-1 feather icon-life-buoy"></i>
					<span>Support</span></a
				>

				<a href="" class="text-gray me-3">Terms &amp; Conditions</a>
				<a href="" class="text-gray">Privacy Policy</a>
			</span>
		</div>
	</div>
</template>
